.customer-info {
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: rgba(255, 255, 255, 0.7);;
    background-color: var(--color-dark-purple);

    line-height: 1.22;

}

@media screen and (min-width: 768px) {
    .customer-info {
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 1200px) {
    .customer-info {
        padding-bottom: 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .customer-info .container {
      padding: 0 77px;
      width: 768px;
    }
}
   
.title-info {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: var(--color-white);
}

@media screen and (min-width: 768px) {
    .title-info {
        font-size: 24px;
        line-height: calc(28 / 24);
        margin-bottom: 50px;
    }
}
    
.title-info>span {
    color: var(--color-orange);
}


    
.info-list {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    color: var(--color-black);
    align-items: center;
}

@media screen and (min-width: 1200px) {
    .info-list {
        
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    color: var(--color-black);
    align-items: center;
    }
}
    
.recepie-step {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.02em;
    position: relative;
}

.info-link{
    font-weight: 400;
    font-size: 24px;
    color:  var(--color-white);
    letter-spacing: -0.02em;
    position: relative;
} 

.info-link:active,
.info-link:visited:active,
.info-link:visited {
  color: var(--color-white);
}

.info-link:hover,
.info-link:focus,
.info-link:visited:hover,
.info-link:visited:focus {
  color: var(--color-orange);
}