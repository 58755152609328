/**
  |============================
  | include css partials with
  | default @import url()
  |============================
*/
@import url('./reset.css');
@import url('./visually-hidden.css');

@import url('./header.css');

@import url('./hero.css');
@import url('./advantages.css');
@import url('./about-us.css');
@import url('./cert.css');
@import url('./ce.css');
@import url('./check-cert.css');
@import url('./customer-info.css');
@import url('./contacts.css');
@import url('./send-ask.css');
@import url('./footer.css');



html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: calc(18 / 14);
  background-color: #fafafa;
}

:root {
  --cubic: cubic-bezier(0.4, 0, 0.2, 1);
  --color-black: #111111;
  --color-orange: rgb(253, 146, 34);
  --color-dark-purple: #1e1823;
  --color-white: #ffffff;
  --color-light-orange: #fcf1e0;
}

.container {
  margin: 0 auto;
  padding: 0 32px;
  max-width: 375px;
}

@media screen and (min-width: 767.98px) {
  .container {
    padding: 0 32px;
    max-width: 768px;
  }
}

@media screen and (min-width: 1199.98px) {
  .container {
    padding: 0 32px;
    max-width: 1200px;
  }
}
section.about-us,
section.cert,
section.ce{
  background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(238, 231, 231, 0.87) 100%), url("../img/mobile/section 1.png");
  /* background-repeat: space; */
  background-repeat: repeat;
  background-position: left top;
  /* background-size: center; */
}