.check-cert {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 0px;
}
.cert-form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    
}
.check-cert-wrapper {
    text-align: center;
}
.check-cert-title {
    padding-top: 20px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: var(--color-orange);
}
.check-cert-title span {
    color: var(--color-black);
}



.check-cert-form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;    
    align-content: center;
    gap: 10px;
    margin-bottom: 20px;
    
}
.check-cert-item{
    
    display: flex;
    
    flex-wrap: wrap;
    /* gap: 20px; */
    
}
.check-cert-for-margin::placeholder{
    font-size: 14px;
    line-height: calc(17 / 14);
    letter-spacing: -0.02em;
    color: rgba(64, 64, 64, 0.3);

}

.check-cert-for-margin{
    border: 1.5px solid rgba(17, 17, 17, 0.05);
    filter: drop-shadow(0px 5.44492px 5.44492px rgba(0, 0, 0, 0.03));
    border-radius: 8px;
    width: 100%;
    height: 45px;
    padding: 14px 18px 14px 0;

    outline: transparent;
    transition: outline 250ms var(--cubic);
}


.label-for-check-cert{
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: calc(14 / 12);
    letter-spacing: -0.02em;

}

.check-cert-item-name{
    margin-bottom: 8px;
}

.check-cert-item-company{
    margin-bottom: 8px;
}

.check-cert-item-email{
    margin-bottom: 8px;
}


.check-cert-item-number{
    margin-bottom: 8px;

}


.check-cert-item-give-company{
    margin-bottom: 8px;
    
}

.check-cert-input-name,.check-cert-input-company,
.check-cert-input-email,.check-cert-input-number,
.check-cert-input-give-company{
    padding-left: 18px;
}


.check-cert-btn-submit{
    width: 109px;
    height: 41px;
    /* padding: 12px 28px; */
text-align: center;
    border: none;
    background-color: var(--color-orange);
    color: var(--color-white);
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    line-height: calc(17 / 14);
    transition: background-color 250ms var(--cubic), color 250ms var(--cubic);
}
.check-cert-btn-submit:hover{
    color: var(--color-orange);
    background-color: var(--color-light-orange);
}

.check-cert-input-email, .check-cert-input-phone, .check-cert-input-card, .check-cert-textarea  {
    margin-top: 8px;
}



.check-cert-for-margin:focus,
.check-cert-for-margin:active,
.check-cert-for-margin:hover{
    outline: 1.5px solid var(--color-orange);

}



/*  */

@media screen and (min-width: 767.98px) {
    /* .check-cert-wrapper {
        text-align: center;
        padding-bottom: 72px;
    } */
    .check-cert-title {
        padding-bottom: 20px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.1;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: var(--color-orange);
    }
    .check-cert-title span {
        color: var(--color-black);
    }

}


.response-message.success {
    color: darkgreen;
  }

  /* Style for error message */
.response-message.error {
    color: darkred;
  }