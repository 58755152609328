.page-heder-container {
  display: flex;
  align-items: center;
  height: 24px;
  
  width: 100%;
}
.header {
  display: flex;
  padding: 12px 0 12px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(244, 244, 247);
}

.heder-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 130px; */
}
.menu {
  display: none;
  justify-content: center;
  gap: 24px;
}
.menu-link {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 14px;
  line-height: calc(16 / 14);
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: var(--color-black);
}
.menu-link .active-link{
  color: var(--color-orange);
}
.menu-link:active{
  color: var(--color-orange);
}
.heder-logo {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  word-spacing: -4;

  font-weight: 700;
  font-size: 14px;
  line-height: calc(18 / 14);
  letter-spacing: -0.03em;
  color: var(--color-black);
}
.logo-icon{
  margin-left: 20px;
  width: 80px;
  height: 40px;
  display: block;
  background-color: rgba(0, 0, 0, 0);
}
.burger-menu-btn {
  display: flex;
  width: 32px;
  height: 32px;
  border: none;
  flex-direction: column;
  border: none;
  background: none;
  justify-content: center;
  align-items: center;
  
}
.logo-tittle {
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: calc(18 / 14);
  /* margin-right: 4px; */
  letter-spacing: -0.03em;
  color: var(--color-black);
}

.logo-tittle-for-morgin{
  margin-right: 4px;
}


@media screen and (min-width: 768px) {
  .header {
    display: flex;
    padding: 24px 0 32px;
  }
  .logo-icon{
    margin-left: 20px;
    width: 120px;
    height: 60px;
    display: block;
    
  }
  .logo-tittle {

    font-style: italic;
    font-weight: 700;
    font-size: 14px;
    line-height: calc(18 / 14);
    letter-spacing: -0.03em;
    color: var(--color-black);
  }
  .heder-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-right: 500px;
  }
  .burger-menu-btn {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    border: none;
    background-color: var(--color-white);
  }
}
@media screen and (min-width: 1200px) {
  .page-heder-container {
    display: flex;
  }
  .logo-icon{
    margin-left: 20px;
    width: 120px;
    height: 60px;
    display: block;
    
  }
  .change-lang{
    margin-left: 40px;
    font-family: inherit;
    font-weight: 500;
    font-size: 14px;
  }
  .change-lang ::after{
    margin-left: auto;
    font-weight: 500;
    font-size: 14px;
  }
  .burger-menu-btn {
    display: none;
    width: 32px;
    height: 32px;
  }
  .header {
    display: flex;
    padding: 24px 0 32px;
  }
  .menu {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-left: 133px;
  }

  .logo-tittle-for-morgin{
    font-weight: 700;
    font-size: 18px;
    line-height: calc(20 / 18);
  }
  
  .logo-tittle {
    /* margin-right: 4px; */
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    line-height: calc(20 / 18);
    letter-spacing: -0.03em;

    color: var(--color-black);
  }
  .heder-nav {
    display: flex;
    align-items: center;
    margin-right: 0;
  }
  
  .menu-link {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    line-height: calc(16 / 14);
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: var(--color-black);
    transition: 250ms var(--cubic);
  }
  .menu-link:hover,
  .menu-link:focus {
    color: var(--color-orange);
  }
  .social-item:not(:first-child) {
    margin-left: 18px;
  }
}
.mobile-menu {
  display: flex;
  background-color: rgba(30, 24, 35, 1);
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  top: 0;
  right: 0;
  width: 220px;
  height: 100%;
  padding: 136px 48px 0 48px;
  overflow: auto;
  transition: opacity 250ms var(--cubic), visibility 250ms var(--cubic);
  overflow: auto;
  z-index: 10;
}
.mobile-menu.is-open {
  visibility: visible;
  opacity: 1;
}
.menu-modal {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  letter-spacing: -0.02em;
  color: var(--color-white);
}
.menu-modal:focus {
  color: var(--color-orange);
}
.menu-modal-item:not(:last-child){
  margin-bottom: 24px;
}
.social-list-modal {
  display: flex;
}
.mobile-menu-btn-closed {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: none;
  background-color: transparent;
}
.modal-btm-close-icon {
  fill: var(--color-white);
}
.mobile-menu-btn-closed:active > svg {
  fill: var(--color-orange);
}
/* .mobile-menu-btn-closed > svg {
  transition: fill 250ms var(--cubic);
} */

@media screen and (min-width: 768px) {
  .mobile-menu {
    width: 290px;
    padding: 156px 83px 0 83px;
    transition: opacity 250ms var(--cubic), visibility 250ms var(--cubic);
    z-index: 10;
  }
  .social-list-modal {
    display: flex;
    position: absolute;
    left: 32px;
    bottom: 24px;
  }
  .mobile-menu-btn-closed {
    top: 24px;
    right: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .mobile-menu {
    display: none;
  }
}
