 .hero {
     max-width: 335px;
     height: 521px;
     background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.4) 100%), url(../img/mobile/hero-img.png);
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
     margin: 0 auto;
     border-radius: 15px;
     padding-bottom: 21px;
     margin-top: 90px;
     margin-bottom: 22px;
     padding-left: 20px;
     padding-right: 20px;

     display: flex;
     
     align-items: flex-end;
     /* height: 100vh;  */
 }
 @media (min-device-pixel-ratio: 2),
 (min-resolution: 192dpi),
 (min-resolution: 2dppx){
    .hero{
        background-image: url(../img/mobile/2x/hero-img@2x.png);
    }
 }
 .hero-caption {
     font-family: "Montserrat";
     color: var(--color-dark-purple);
     font-weight: 500;
     font-size: 20px;
     line-height: calc(24 / 20);
     letter-spacing: -0.03em;
     text-align: center;
     width: 290px;
     height: 72px;
     
 }
 .hero-caption em{
    color: var(--color-orange);
 }
 .hero_content {
     
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
     
     gap: 50px;
     background-color: rgba(240, 248, 246, 0.7); 
     border-radius: 5px;    
     
 }
.hero-tel-list{
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
margin: 0 auto;

 }

 @media screen and (min-width:768px) and (max-width:1199px) {
     .hero {
         margin-bottom: 22px;
         margin-top: 90px;
     }
 }

 @media screen and (min-width:768px) {
     .hero {
         background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.4) 100%), url(../img/desktop/hero-img.png);
         max-width: 704px;
         padding-bottom: 40px;
         padding-left: 32px;
         padding-right: 32px;
         margin-top: 90px;
     }
     @media (min-device-pixel-ratio: 2),
     (min-resolution: 192dpi),
     (min-resolution: 2dppx){
        .hero{
            background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.4) 100%), url(../img/desktop/hero-img.png);
        }
     }
     .hero-caption {
         width: 475px;
         height: 102px;
         font-weight: 500;
         font-size: 28px;
         line-height: calc(34 / 28);
     }

     .hero_content {
         align-items: flex-start;
     }

 }

 @media screen and (min-width:1200px) {
     .hero {
         max-width: 1136px;
         background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.4) 100%), url(../img/desktop/hero-img.png);
        margin: 0 auto;
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 22px;
        margin-top: 90px;
     }

     @media (min-device-pixel-ratio: 2),
     (min-resolution: 192dpi),
     (min-resolution: 2dppx){
        .hero{
            background-image: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.4) 100%), url(../img/desktop/hero-img.png);
        }
     }

    
 }