.contacts {
    margin-top: 20px;
    margin-bottom: 20px;
    /* padding: 80px 0px; */
}

.contacts-title {
    text-align: center;
    /* padding-top: 20px; */
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: var(--color-orange);
}
.contacts-title span {
    color: var(--color-black);
}
.contacts-details{
    display: flex;
        flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 60px;
}
.full-addr{
    margin-bottom: 6px;
}
.msg-icon{
    display: inline;
}
.tel-item{
    margin-bottom: 6px;
}
/* .tel-item a{
    display: block;
    cursor: pointer;
} */
.tel-item a:hover,
.tel-item a:focus {
  color: var(--color-orange);
}


/*  */

@media screen and (min-width: 767.98px) {
    /* .contacts-wrapper {
        text-align: center;
        padding-bottom: 72px;
    } */
    .contacts-title {
        padding-bottom: 50px;
        font-weight: 600;
        margin-bottom: 12px;
        font-size: 24px;
        line-height: 1.1;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: var(--color-orange);
    }
    .contacts-title span {
        color: var(--color-black);
    }

}


.contacts-response-message.success {
    color: darkgreen;
  }

  /* Style for error message */
.contacts-response-message.error {
    color: darkred;
  }