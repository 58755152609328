.footer {
  background-color: var(--color-dark-purple);
  padding-top: 12px;
  padding-bottom: 12px;
}

.slogan {
  color: var(--color-white);
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 16px;
}

.accent-footer {
  color: var(--color-orange);
}

.footer-title {
  color: var(--color-white);
  margin-bottom: 12px;
  font-weight: 500;
}

.footer-top {
  display: flex;
  align-items: flex-start;
  justify-content:  space-between;
}

.footer-contacts {
  flex: 1;
  margin-right: 20px;
}

.address {
  font-style: normal;
}

.contacts-list {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 4px;
  line-height: 1.4;
}

.footer-contact-link {
  display: block;
  color: inherit;
  transition: color 250ms ease;
}

.footer-contact-link:hover,
.footer-contact-link:focus {
  color: var(--color-orange);
}

.links-list {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-height: 1.4;
}

.footer-link {
  color: inherit;
  transition: color 250ms ease;
}

.footer-link:hover,
.footer-link:focus {
  color: var(--color-orange);
}

@media screen and (min-width: 767.98px) {
  .footer {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .slogan {
    font-size: 20px;
  }

  .footer-top {
    justify-content:  space-between;
    align-items: center;
  }

  .footer-contacts {
    margin-right: 40px;
  }

  .contacts-list {
    gap: 6px;
  }

  .icons-list {
    gap: 12px;
  }

  .footer-icon {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 1199.98px) {
  .footer {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .slogan {
    font-size: 24px;
  }

  .footer-top {
    justify-content:  space-between;
  }

  .footer-right .contacts {
    margin-right: 60px;
  }

  .contacts-list {
    gap: 8px;
  }

  .icons-list {
    gap: 10px;
  }

  .footer-icon {
    width: 24px;
    height: 24px;
  }
}
