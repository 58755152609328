.send-ask {
    margin-top: 20px;
    margin-bottom: 20px;
    /* padding: 80px 0px; */
}
.ask-form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    
}
.send-ask-wrapper {
    text-align: center;
}
.send-ask-title {
    /* padding-top: 20px; */
    /* margin-bottom: 32px; */
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: var(--color-black);
}
.send-ask-title span {
    color: var(--color-orange);
}


.send-ask-form{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;    
    align-content: center;
    gap: 10px;
    /* margin-bottom: 20px; */
    
}
.send-ask-item{
    
    display: flex;
    
    flex-wrap: wrap;
    /* gap: 20px; */
    
}
.send-ask-for-margin::placeholder{
    font-size: 14px;
    line-height: calc(17 / 14);
    letter-spacing: -0.02em;
    color: rgba(64, 64, 64, 0.3);

}

.send-ask-for-margin{
    border: 1.5px solid rgba(17, 17, 17, 0.05);
    filter: drop-shadow(0px 5.44492px 5.44492px rgba(0, 0, 0, 0.03));
    border-radius: 8px;
    width: 100%;
    height: 45px;
    padding: 14px 18px 14px 0;

    outline: transparent;
    transition: outline 250ms var(--cubic);
}


.label-for-send-ask{
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: calc(14 / 12);
    letter-spacing: -0.02em;
    text-align: left;

}

.send-ask-item-name,
.send-ask-item-email{
    margin-bottom: 8px;
}




.send-ask-input-name,
.send-ask-input-email,
.send-ask-textarea{
    padding-left: 18px;
}

.send-ask-item-textarea{
    display: flex;
    flex-direction: column;
    
}


.send-ask-textarea{
    border: 1.5px solid rgba(17, 17, 17, 0.05);
    filter: drop-shadow(0px 5.44492px 5.44492px rgba(0, 0, 0, 0.03));
    border-radius: 8px;
    /* width: 263px; */
    height: 140px;
    resize: none;
}

.send-ask-btn-submit{
    width: 109px;
    height: 41px;
    /* padding: 12px 28px; */
    text-align: center;
    margin-top: 18px;
    margin-bottom: 10px;
    border: none;
    background-color: var(--color-orange);
    color: var(--color-white);
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    line-height: calc(17 / 14);
    transition: background-color 250ms var(--cubic), color 250ms var(--cubic);
}
.send-ask-btn-submit:hover{
    color: var(--color-orange);
    background-color: var(--color-light-orange);
}

.send-ask-input-email, .send-ask-input-phone, .send-ask-input-card, .send-ask-textarea  {
    margin-top: 8px;
}



.send-ask-for-margin:focus,
.send-ask-for-margin:active,
.send-ask-for-margin:hover{
    outline: 1.5px solid var(--color-orange);

}



/*  */

@media screen and (min-width: 767.98px) {
    /* .send-ask-wrapper {
        text-align: center;
        padding-bottom: 72px;
    } */
    .send-ask-title {
        padding-bottom: 50px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.1;
        letter-spacing: -0.03em;
        text-transform: uppercase;
        color: var(--color-black);
    }
    .send-ask-title span {
        color: var(--color-orange);
    }

}


.contacts-response-message.success {
    color: darkgreen;
  }

  /* Style for error message */
.contacts-response-message.error {
    color: darkred;
  }