.about-us {
  padding: 42px 20px;
 
 
}
.about-us .container{
  background-color: white;
  border-radius: 15px;
  
}
.about-us-title {
  max-width: 283px;
  margin: 0 auto 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: calc(24 / 20);
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}

.about-us-title-accent {
  color: var(--color-orange);
}

.about-us-subtitle {
  max-width: 335px;
  margin-bottom: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: calc(18 / 14);
  text-align: center;
  letter-spacing: -0.02em;
}

.left, .center, .right{
  max-width: 335px;
  margin-bottom: 10px;
}
.lng-left-title, .lng-center-title, .lng-right-title{
  margin-bottom: 20px; 
}

.about-list {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  color: var(--color-black);
}

.about-step {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.02em;
  position: relative;
}

  
.about-step::before {
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: -16px;
  top: 5px;
  background-color: #4C4C4C;
  border: 1px solid #ECEDED;
}

.about-us-texts{    
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;    
}

.lng-center, .lng-right1, .lng-right2{
  margin-bottom: 6px;
  letter-spacing: -0.02em;
  text-align: justify;
  margin-top: 6px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.lng-right2{
  margin-top: 6px;
  
}


@media screen and (min-width: 768px) and (max-width: 1200px) {
  .about-us-texts{    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;    
  }
}

@media screen and (min-width: 768px) {
  .about-us-card {
    max-width: 336px;
  }
  .about-us {
    padding: 60px 0px;
  }
  .about-us-title {
    max-width: 411px;
    margin: 0 auto 28px;
    font-size: 24px;
    line-height: calc(28 / 24);
    letter-spacing: -0.03em;
  }
  .about-us-subtitle {
    display: block;
    max-width: 486px;
    margin: 0 auto 50px;
  }
  .about-us-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
    margin: 0 auto;
  }
  .about-us-texts{    
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;    
  }
}

@media screen and (min-width: 1200px) {
  .about-us {
    padding: 60px 0px;
  }
  .about-us-list {

    display: flex;
    justify-content: center;
    gap: 18px;
  }

  .about-us-texts{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
  }
